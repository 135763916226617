import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×8\\@70%1RM`}</p>
    <p>{`DB Bent Side Laterals 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`30-20-10 reps each:`}</p>
    <p>{`S2OH (95/65) (RX+ 115/75)`}</p>
    <p>{`Box Jumps (24/20″)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      